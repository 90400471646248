import { Component, OnInit } from '@angular/core';

import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApirestService } from './services/apirest.service';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ComponentsService } from './services/components.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';


declare var window;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;

  imagenPerfil:string = "assets/imgs/placeholder-person.png";
  nombrePerfil:string = '';

  public appPagesNoLogin = [
    {
      title: 'Tienda',
      url: 'tienda',
      icon: 'storefront',
      visible: true
    },
    {
      title: 'Tip del día',
      url: 'consejos',
      icon: 'bulb',
      visible: true
    },
    {
      title: 'Testimoniales',
      url: 'testimoniales',
      icon: 'happy',
      visible: true
    },
    {
      title: 'Acerca de',
      url: 'acerca',
      icon: 'information-circle',
      visible: true
    },
    {
      title: 'Mis Playlists',
      url: 'playlist',
      icon: 'musical-notes',
      visible: true
    }
  ];

  public appPages = [
    {
      id: 0,
      title: 'Mi cuenta',
      url: 'config',
      icon: 'person',
      visible: true
    },
    {
      id: 1,
      title: 'Mis retos',
      url: 'programas',
      icon: 'heart',
      visible: false
    },
    {
      id: 2,
      title: 'Rutinas especiales',
      url: 'rutinas-especiales',
      icon: 'medal',
      visible: false
    },
    {
      id: 3,
      title: 'Mis calificaciones',
      url: 'calificaciones',
      icon: 'checkmark-done-circle',
      visible: false
    },
    {
      id: 4,
      title: 'Recetarios',
      url: 'recetarios',
      icon: 'nutrition',
      visible: false
    },
    {
      id: 5,
      title: 'Tienda',
      url: 'tienda',
      icon: 'storefront',
      visible: true
    },
    {
      id: 6,
      title: 'Tip del día',
      url: 'consejos',
      icon: 'bulb',
      visible: true
    },
    {
      id: 7,
      title: 'Testimoniales',
      url: 'testimoniales',
      icon: 'happy',
      visible: true
    },
    {
      id: 8,
      title: 'Preguntas frecuentes',
      url: 'faq',
      icon: 'help-circle',
      visible: true
    },
    {
      id: 9,
      title: 'Acerca de',
      url: 'acerca',
      icon: 'information-circle',
      visible: true
    },
    {
      title: 'Mis Playlists',
      url: 'playlist',
      icon: 'musical-notes',
      visible: true
    }
  ];


  mainMenu:any = this.appPagesNoLogin;

  sesionIniciada:boolean = false;

 

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private ga: GoogleAnalytics,
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    private screenOrientation: ScreenOrientation,
    private apirestProvider: ApirestService,
    private componentsProvider: ComponentsService
    ) {

      this.initializeApp();

     
      this.componentsProvider.myData.subscribe((data) => {
        console.log(data);
        console.log('logueado', );
        this.sesionIniciada = this.apirestProvider._storage.ajustes.logueado;
        if( this.sesionIniciada ){
          this.cargarPerfil();

        }
       
      });

      this.apirestProvider.newProduct.subscribe((data) => {
        console.log(data);
        //llamo al recibir la notificación de nuevo producto
        this.sesionIniciada = this.apirestProvider._storage.ajustes.logueado;
        if( this.sesionIniciada ){
          this.recargarMenu();
        }
       
      });

  }


  initializeApp() {

    this.platform.ready().then(() => {

       

      if (this.platform.is('cordova')) {
        this.screenOrientation.lock( 'portrait-primary' );

        this.ga.startTrackerWithId('UA-131543411-1')
          .then(() => {
            //console.log('Google analytics is ready now');
              this.ga.trackView('test');
            // Tracker is ready
            // You can now track pages or set additional information such as AppVersion or UserId
          })
          .catch(e => console.log('Error starting GoogleAnalytics', e));

        
      }

      this.apirestProvider.cargarStorage()
            .then( () => {

              //console.log('user-data',  this.apirestProvider._storage );

         
                if( this.apirestProvider._storage.ajustes.logueado ){

                  this.sesionIniciada = this.apirestProvider._storage.ajustes.logueado;
                  
                  this.cargarPerfil();
  
                  if (this.platform.is('cordova')) {  
  
                    this.apirestProvider.initOneSignal();
  
                  }
  
                  this.router.navigate(['/consejos']);
  
                }else{
                  
                  
                  this.mainMenu = this.appPagesNoLogin;
                  this.router.navigate(['']);
  
                }

 

              
      });


      
      this.statusBar.overlaysWebView(false); 
      this.statusBar.backgroundColorByHexString('#ebedee');
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }




  async navigateToExit(){

    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: '¿Quiéres cerrar sesión?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            //console.log('Confirm Okay');
            this.logout();
          }
        }
      ]
    });

    await alert.present();
  }


  logout(){

      this.componentsProvider.present();
  
      this.apirestProvider.logout ( this.apirestProvider._storage.ajustes.userIdOneSignal )
          .subscribe( ( datos )=> {
            
            console.log(datos);
  
              /* if (datos['status'] == "ERROR"){
                this.msgAlert( 'ERROR', datos['message']);
                
                
              }else{
                this.apirestProvider._storage.ajustes.logueado = false;
                
                this.apirestProvider.borrarStorage();
                this.mainMenu = this.appPaguesNoLogin;
 
                
              } */

              this.apirestProvider._storage.ajustes.logueado = false;
  
              this.componentsProvider.dismiss();

              this.clearProfile();
              this.router.navigate(['/consejos']);

          },
          err => {
            console.log(err);
            this.componentsProvider.dismiss();
            this.clearProfile();
          }
          );
  

  }

  async msgAlert( _msg1:string, _msg2:string ) {
    const alert = await this.alertController.create({
      header: _msg1,
      message: _msg2,
      buttons: ['OK']
    });

    await alert.present();
  }

  

  clearProfile(){
    //this.userinfo = null;
    this.sesionIniciada = false;
    this.mainMenu = this.appPagesNoLogin;
    this.apirestProvider.borrarStorage();
  }


  async cargarPerfil(){

   
    /* const loading = await this.loadingCtrl.create({
      message: 'cargando configuraciones...'
    });
    await loading.present(); */

    this.apirestProvider.getProfile()
      .subscribe(datos => {

        //console.log('perfil:', datos );

        if( datos['code'] == 1 ){
 
          this.mainMenu = this.appPages;
          this.apirestProvider._storage.usuariodata = datos['data'];
          this.apirestProvider.guardarStorage();

          this.apirestProvider.faltaCalificar == datos['data'].pending_scores;

          if( datos['data'].has_programs ){
            this.mainMenu[1].visible = true;
            this.mainMenu[3].visible = true;
          }

          if( datos['data'].has_specials ){
            this.mainMenu[2].visible = true;
          }

          if( datos['data'].has_recipe_books ){
            this.mainMenu[4].visible = true;
          }

          if( datos['data'].fotografia != null ){
            this.imagenPerfil = datos['data'].fotografia;
          }

          if( datos['data'].name != null ){
            this.nombrePerfil = datos['data'].name;
          }


          if (this.platform.is('cordova')) { 
            this.router.navigate(['/configura-inicio']);

          }else{

            if( this.apirestProvider.cart.length > 0 ){
              this.router.navigate(['/carrito']);
            }else{
              this.router.navigate(['/consejos']);
            }

          }

        }else{
          this.clearProfile();
        }
 
      },
      error => {
        //loading.dismiss();
        this.clearProfile();
       console.log('error de conexión');
        console.error(error);
      });

  }

  async recargarMenu(){

    console.log('recreo menu');

     const loading = await this.loadingCtrl.create({
      message: 'cargando configuraciones...'
    });
    await loading.present();

    this.apirestProvider.getProfile()
      .subscribe(datos => {

        //console.log('perfil:', datos );

        if( datos['code'] == 1 ){
 
          this.mainMenu = this.appPages;
          this.apirestProvider._storage.usuariodata = datos['data'];
          this.apirestProvider.guardarStorage();

   
          if( datos['data'].has_programs ){
            this.mainMenu[1].visible = true;
            this.mainMenu[3].visible = true;
          }

          if( datos['data'].has_specials ){
            this.mainMenu[2].visible = true;
          }

          if( datos['data'].has_recipe_books ){
            this.mainMenu[4].visible = true;
          }

          if( datos['data'].fotografia != null ){
            this.imagenPerfil = datos['data'].fotografia;
          }

          if( datos['data'].name != null ){
            this.nombrePerfil = datos['data'].name;
          }


          loading.dismiss();

        }else{
          this.clearProfile();
        }
 
      },
      error => {
        loading.dismiss();
        this.clearProfile();
       console.log('error de conexión');
        console.error(error);
      });

  }


  


}

import { AddressAddPageModule } from './pages/address-add/address-add.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule }    from '@angular/common/http';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TiendaDetailPageModule } from './pages/tienda-detail/tienda-detail.module';
import { TimerCronPageModule } from './pages/timer-cron/timer-cron.module';
import { ProgramasFechaPageModule } from './pages/programas-fecha/programas-fecha.module';
import { RutinasEspecialesFechaPageModule } from './pages/rutinas-especiales-fecha/rutinas-especiales-fecha.module';

import { ConfigDatoseditPageModule } from './pages/config-datosedit/config-datosedit.module';
import { CalificacionesEditPageModule } from './pages/calificaciones-edit/calificaciones-edit.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ConfigUsereditPageModule } from './pages/config-useredit/config-useredit.module';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: '',
        swipeBackEnabled: false
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TiendaDetailPageModule,
    TimerCronPageModule,
    ProgramasFechaPageModule,
    RutinasEspecialesFechaPageModule,
    ConfigDatoseditPageModule,
    ConfigUsereditPageModule,
    CalificacionesEditPageModule,
    AddressAddPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Facebook,
    InAppBrowser,
    OneSignal,
    ScreenOrientation,
    GoogleAnalytics,
    File,
    FileTransfer,
    Camera,
    SignInWithApple,
    SocialSharing,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ConfigUsereditPageRoutingModule } from './config-useredit-routing.module';

import { ConfigUsereditPage } from './config-useredit.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ConfigUsereditPageRoutingModule
  ],
  declarations: [ConfigUsereditPage]
})
export class ConfigUsereditPageModule {}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-calificaciones-mensaje',
  templateUrl: './calificaciones-mensaje.page.html',
  styleUrls: ['./calificaciones-mensaje.page.scss'],
})
export class CalificacionesMensajePage implements OnInit {

  constructor( public modalCtrl: ModalController,
              public router: Router
              ) { }

  ngOnInit() {
  }


  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }


  onClickModal(){

    this.closeModal(true);
    this.router.navigate(['/calificaciones'])

  }


}

import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { ApirestService } from './apirest.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CalificacionesMensajePage } from '../pages/calificaciones-mensaje/calificaciones-mensaje.page';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  isLoading:boolean = false;

  public myData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  constructor( public loadingCtrl: LoadingController,
    public alertController: AlertController,
    private toastController: ToastController,
    private apirestProvider: ApirestService,
    public modalCtrl: ModalController,
    public router: Router ) {

      

     }


     async present() {
      this.isLoading = true;
      return await this.loadingCtrl.create({
        message: 'cargando',
        duration: 10000
      })
      .then( a => {
        a.present().then( () => {
          if( !this.isLoading ){
            a.dismiss().then( () => console.log('abort') );
          }
        });
        
      } );
      
      
  }
  
  async dismiss(){
    this.isLoading = false;
    return await this.loadingCtrl.dismiss()
    .then( () => console.log('dismissed') );
  }
  
  // SINGLE ALERT COMPONENT
  
  async singleAlert( _header:string, _subheader:string, _message:string ) {
    const alert = await this.alertController.create({
      header: _header,
      subHeader: _subheader,
      message: _message,
      buttons: ['OK']
    });
  
    await alert.present();
  }
  
  
  //ALERT CONFIRM
  
  async presentAlertConfirm( _message:string ) {
    const alert = await this.alertController.create({
      header: 'Confirmar!',
      message: _message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Okay');
            this.apirestProvider.borrarStorage();
            this.router.navigate(['']);
          }
        }
      ]
    });
  
    await alert.present();
  }
  
  //TOAST
  async presentToast( _msg1:string ) {
    const toast = await this.toastController.create({
      message: _msg1,
      duration: 2000,
      color: 'dark'
    });
    toast.present();
  }
  
  async errorAlert( _header:string, _message:string ) {
    const alert = await this.alertController.create({
      header: _header,
      message: _message,
      buttons: ['OK']
    });
  
    await alert.present();
  }

  // MODAL AVISO CALIFICACIONES

  async openModalCalif(  ) {
    const modal = await this.modalCtrl.create({
      component: CalificacionesMensajePage,
      cssClass: 'auto-height',
      backdropDismiss: true,
      componentProps: null
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        
        this.apirestProvider.aviso = true;
        console.log( 'aviso',  this.apirestProvider.aviso);

        if (dataReturned.data == false) {
          //console.log(' no se hicieron cambios');
        } else {

         //si es true hacer algo
 
        }
      }

    });

    return await modal.present();
  }


}

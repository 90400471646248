import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { ApirestService } from 'src/app/services/apirest.service';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
  selector: 'app-rutinas-especiales-fecha',
  templateUrl: './rutinas-especiales-fecha.page.html',
  styleUrls: ['./rutinas-especiales-fecha.page.scss'],
})
export class RutinasEspecialesFechaPage implements OnInit {

  @Input() id: any;

  today:any;
  formAgendar:FormGroup;
  myCurrentDate:any;
  myPastDate:any;
  limit:any;
  myCurrentYear:any;

  constructor(public loadingCtrl:LoadingController,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    private platform: Platform,
    private apirestProvider:ApirestService,
    private componentsProvider: ComponentsService
    ) { 
      this.platform.backButton.subscribeWithPriority(10, () => {
        console.log('Handler was called!');
        this.closeModal(false);
      });

    }

   

  ngOnInit() {

    this.myCurrentDate = new Date();
    this.myPastDate=new Date(this.myCurrentDate);
    this.myPastDate.setDate(this.myPastDate.getDate() - 30);
    this.myCurrentYear =  new Date().getFullYear();

     

    this.formAgendar = this.formBuilder.group({
      fechainicio: ['', Validators.required ]
    });

    this.limit = this.myPastDate.toJSON().split('T')[0];
    this.today = new Date().toJSON().split('T')[0];
    this.formAgendar.get('fechainicio').setValue(this.today);
  }



  async programarInicio(){

    console.log('fecha', this.formAgendar.value.fechainicio);

    const loading = await this.loadingCtrl.create({
      message: 'guardando fecha...'
    });
    await loading.present();

    let tempDate:string = this.formAgendar.value.fechainicio.toString();
    let fechaI:string = tempDate.split('T')[0];
 
    console.log(fechaI);

    this.apirestProvider.putStartSpecialDate( this.id, fechaI , 'start' )
              .subscribe(datos => {
                //this.programs = datos.data;
                
                if (datos['code'] == 1){
                    
                  this.closeModal( true );
                  console.log(datos);

                }else if(datos['code'] == 0){
                  this.componentsProvider.singleAlert('ERROR', '', datos['message'] );
                }

                loading.dismiss();   

              },
              error => {
                console.error(error);
                loading.dismiss();     
              });   



  }

 


  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }

}

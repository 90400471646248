import { Component, OnInit, Input } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ApirestService } from 'src/app/services/apirest.service';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
  selector: 'app-calificaciones-edit',
  templateUrl: './calificaciones-edit.page.html',
  styleUrls: ['./calificaciones-edit.page.scss'],
})
export class CalificacionesEditPage implements OnInit {

  formCalif: FormGroup;
  @Input() id: any;
  @Input() semana: any;
  @Input() lastWeek: boolean;
  approved:boolean = false;

  constructor( public modalCtrl: ModalController,
              public navParams: NavParams,
              public platform: Platform,
              private formBuilder: FormBuilder,
              public loadingCtrl: LoadingController,
              private ga: GoogleAnalytics,
              private apirestProvider: ApirestService,
              private componentsProvider: ComponentsService
              ) { 

                this.platform.backButton.subscribeWithPriority(10, () => {
                  console.log('Handler was called!');
                  this.closeModal(false);
                });

  }

  ionViewDidLoad(){
    this.platform.ready().then(() => {
      this.ga.trackView('Calificarme')
          .then(() => {})
          .catch(e => console.log(e));
    });
    
  }

  ionViewDidEnter() {
    document.addEventListener("backbutton",function(e) {
      console.log("disable back button");
    }, false);
  }

  ngOnInit() {
    

    console.log('id=', this.id );
    console.log('semana=', this.semana );

    this.formCalif = this.formBuilder.group({
      dieta: ['', Validators.required ],
      ejercicio: ['', Validators.required ],
    });


  }

  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }

  cambioApproved(e){
    //console.log(e.detail.checked);
    this.approved = e.detail.checked;
  }

  async editarCalificaciones(){

 
    const loading = await this.loadingCtrl.create({
      message: 'cargando ...'
    });
    await loading.present();

    let obj:any = {
      semana: this.semana,
      dieta: this.formCalif.value.dieta,
      ejercicio: this.formCalif.value.ejercicio
    }

    //console.log( 'valor', this.approved);

    if( this.lastWeek ){
      let ap = 0;
      if( this.approved == true ){
        ap = 1;
      }
      obj.approved = ap;
    }

    console.log('objeto', obj );

      this.apirestProvider.putScore( this.id, obj )    
              .subscribe(datos => {

                //console.log( datos );

                loading.dismiss();

                if(datos['code'] == 1){
                  this.closeModal( true );
                }else if ( datos['code'] == 0 ){
                  this.componentsProvider.errorAlert('ERROR', datos['message'] );
                }

                
              },
              error =>{
                console.error(error);
                loading.dismiss();
              });   

  
     

  }

}

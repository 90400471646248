import { Component, OnInit,Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-timer-cron',
  templateUrl: './timer-cron.page.html',
  styleUrls: ['./timer-cron.page.scss'],
})
export class TimerCronPage implements OnInit {

  @Input() tiempo: any;
  //@ViewChild(TimerComponent) timer: TimerComponent;

  constructor( public modalCtrl: ModalController,
              private platform: Platform
              ) { 
    this.platform.backButton.subscribeWithPriority(10, () => {
      console.log('Handler was called!');
      this.closeModal(false);
    });
  }

  

  ngOnInit() {

    console.log('tiempo=', this.tiempo );

  }


  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RutinasEspecialesFechaPageRoutingModule } from './rutinas-especiales-fecha-routing.module';

import { RutinasEspecialesFechaPage } from './rutinas-especiales-fecha.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RutinasEspecialesFechaPageRoutingModule
  ],
  declarations: [RutinasEspecialesFechaPage]
})
export class RutinasEspecialesFechaPageModule {}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApirestService {

  // PROD
  public url:string = "https://aleestefania.stage.aetfitness.com.mx";
  // DEV
  //public url:string = "https://aleestefania.dev.aetfitness.com.mx";

  public apiUrl: string = '/api/v2.2'
  private clientId: string = '1';
  private clientSecret: string = 'LzQpYCQNLgA4zpRusliGm2Uli5gDMxoOdCEXnMAg'; 

  public sender_id:string = '67737500700';
  public oneSignalAppId:string = 'b84b66dd-a548-4457-972d-09357f3dff80';

  public newProduct: BehaviorSubject<any> = new BehaviorSubject<any>(null);



  public _storage:any = {
    ajustes:{
      'logueado': false,
      'token': null,
      'userIdOneSignal': null,
      'version': "2.2.0",
    },
    usuariodata:null,
    rutinas: {},
    especiales: {}

  };

  tokenIdApple:string = null;

  cart: any[] = [];

  public faltaCalificar: boolean = false;
  public aviso:boolean = false;


  constructor(  private storage: Storage, 
    private platform: Platform, 
    private oneSignal: OneSignal,
    private router: Router,
    private http: HttpClient
    ) { 

    }


    login( credentials ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        })
      }
  
      //Http params
      let _credentials = {
        'username': credentials.username,
        'password': credentials.password,
        'grant_type': 'password',
        'client_id': this.clientId,
        'client_secret': this.clientSecret
      }
  
  
      //console.log(_credentials);
  
      return this.http.post(this.url + '/oauth/token', _credentials, httpOptions)
        .pipe(
          tap(res => {
            //console.log('token es: ', res['token'].access_token);
  
            //console.log('respuesta', res);
            this._storage.ajustes.logueado = true;
            this._storage.ajustes.token = res['access_token'];
            this.guardarStorage();
           
  
          }),
          catchError(e => {
            //this.showAlert(e.error.msg);
            console.log('error', e);
            throw new Error(e);
          })
        );
    }

  
    logout( _token ) {
  
      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json',  
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Authorization': 'Bearer ' + this._storage.ajustes.token      
        })
      } 
  
      let params = {
        token: _token
      };
        
      return this.http.post(this.url+ this.apiUrl + '/logout', params, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
            catchError(e => {
              throw new Error(e);
            })
          
        );
    }


    postPushDevices(params) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      //console.log(params);
  
      return this.http.post(this.url + this.apiUrl + '/push_devices', params, httpOptions)
        .pipe(
          tap(res => {
            return res;
          }),
          catchError(e => {
            throw new Error(e);
          })
  
        );
    }
  
    facebookLogin( params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json'     
        })
      } 

  
      return this.http.post(this.url+ this.apiUrl + '/facebook', params, httpOptions )
        .pipe(
          tap(res => {

            this._storage.ajustes.logueado = true;
            this._storage.ajustes.token = res['data']['facebook'].accessToken;
            this._storage.usuariodata = res['data']['user'];
            console.log( res );
            this.guardarStorage();

            }),
          catchError(e => {
            throw new Error(e);
          })
        );
    }

    appleLogin( params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json'     
        })
      } 

      console.log( params );

  
      return this.http.post(this.url + this.apiUrl +  '/apple', params, httpOptions )
        .pipe(
          tap(res => {

            /* console.log('****************************');
            console.log( 'respuesta api cupernic', res );
            console.log('****************************'); */

           /*  console.log('inicio tipo de');
            console.log( typeof res['apple'].accessToken );
            console.log( 'fin tipo de' ); */

            this._storage.ajustes['logueado'] = true;
            this._storage.ajustes['token'] = res['data']['apple'].accessToken;
            //this.tokenIdApple = res['data']['apple'].accessToken;
            this._storage['usuariodata'] = res['data']['user'];
            
            this.guardarStorage();

            return res;

           

            }),
          catchError(e => {
            
            throw new Error(e);
            
            
          })
        );
    }


    register( params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json'     
        })
      } 
        
      console.log(params);
  
      return this.http.post(this.url+ this.apiUrl +'/register', params, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
          catchError(e => {
            throw new Error(e);
          })
        );
    }


    recover( params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json'     
        })
      } 
        
      console.log(params);
  
      return this.http.post(this.url+ this.apiUrl + '/recover', params, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
          catchError(e => {
            throw new Error(e);
          })
        );
    }


    getTips() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/tips', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )    
    }

    getProducts() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      };

      const httpOptions2 = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }

      let option:any = httpOptions;

      if (this._storage['ajustes'].logueado == true ){
        option = httpOptions2
      }
  
      return this.http.get( this.url + this.apiUrl + '/market/products', option)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getPrograms() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/programs', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    putStartProgramDate( _program_id, _fecha, _action ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json',  
          'Authorization': 'Bearer ' + this._storage.ajustes.token      
        })
      } 
  
      return this.http.put(this.url + this.apiUrl +  '/program/' + _program_id + '/' + _action, { inicio: _fecha }, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
            catchError(e => {
              throw new Error(e);
            })
          
        );
    }

    putStopProgramDate( _program_id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json',  
          'Authorization': 'Bearer ' + this._storage.ajustes.token      
        })
      } 
  
      return this.http.put(this.url + this.apiUrl +  '/program/' + _program_id + '/stop', {}, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
            catchError(e => {
              throw new Error(e);
            })
          
        );
    }

    getProgram( _params ) {

      let params = '?semana=' + _params['numSemana'] + '&dia=' +  _params['numDia'];

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }

      if (_params['numSemana'] == ''  ) {

        params = '';

      }

      console.log( 'parametors enviados', this.url + this.apiUrl + '/program/' + _params['id'] + params);
  
      return this.http.get( this.url + this.apiUrl + '/program/' + _params['id'] + params, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getRecipes() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/recipe_books', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getRecipe( _id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/recipe_book/' + _id, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getSpecials() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/specials', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    putStartSpecialDate( _program_id, _fecha, _action ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json',  
          'Authorization': 'Bearer ' + this._storage.ajustes.token      
        })
      } 
  
      return this.http.put(this.url + this.apiUrl +  '/special/' + _program_id + '/' + _action, { inicio: _fecha }, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
            catchError(e => {
              throw new Error(e);
            })
          
        );
    }

    putStopSpecialDate( _program_id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Accept': 'application/json',  
          'Authorization': 'Bearer ' + this._storage.ajustes.token      
        })
      } 
  
      return this.http.put(this.url + this.apiUrl +  '/special/' + _program_id + '/stop', {}, httpOptions )
        .pipe(
          tap(res => {
            return res;
            }),
            catchError(e => {
              throw new Error(e);
            })
          
        );
    }

    getSpecialDays( _id, _semana ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/special/' + _id + '/days?semana=' + _semana, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getSpecial( _id, _semana, _dia ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/special/' + _id + '?semana=' + _semana + '&dia=' + _dia, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getSpecialExercises( _id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/special/' + _id + '/exercises', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    getFaqs() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/faq', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getTestimonials() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/testimonials', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getInfo( _id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/info?id=' + _id , httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getTimezones() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/timezones', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getProfile() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/profile', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    putProfile( _params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.put( this.url + this.apiUrl + '/profile', _params,  httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getInvoices() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/invoices', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getCard() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/card', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    deleteCard( _id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.delete( this.url + this.apiUrl + '/card/' + _id, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    checkCoupon( _items, _cupon ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.post( this.url + this.apiUrl + '/market/coupon', { items: _items, coupon: _cupon }, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    cartBundle( _items ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json'
        })
      };

      const httpOptions2 = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }

      let option:any = httpOptions;

      if (this._storage['ajustes'].logueado == true ){
        option = httpOptions2
      }
  
      return this.http.post( this.url + this.apiUrl + '/bundles/check', { items: _items }, option)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    checkout( _items, _metodopago, _cupon, _address, _card ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.post( this.url + this.apiUrl + '/market/checkout', { items: _items, metodo_pago: _metodopago, coupon: _cupon, address: _address,  card: _card }, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    purchase( _items, _metodopago, _metodoenvio, _cupon, _address, _card, _fact ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }

      let obj:any = { items: _items, metodo_pago: _metodopago, metodo_envio: _metodoenvio, address: _address, card: _card }
      if( _cupon != null ){
        obj.coupon = _cupon;
      }

      if( _fact != null ){
        obj.company = _fact.company;
        obj.rfc = _fact.rfc;
        obj.cfdi = _fact.cfdi;
        obj.zip = _fact.zip;
      }

      //console.log(obj);
  
      return this.http.post( this.url + this.apiUrl + '/market/purchase', obj, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getShippingTypes() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/market/shipping_methods', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    getScores() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/scores', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getScore( _id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/score/' + _id, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    putScore( _id, _params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.put( this.url + this.apiUrl + '/score/' + _id , _params,  httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    getAddress() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/address', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    postAddress( params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.post( this.url + this.apiUrl + '/address', params, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    putAddress( _id, params ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.put( this.url + this.apiUrl + '/address/' + _id, params, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    deleteAddress( _id ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.delete( this.url + this.apiUrl + '/address/' + _id, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    getPlayLists() {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this._storage.ajustes.token
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/playlists', httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }

    getStates( _countryId ) {

      // Http Headers
      const httpOptions = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
        })
      }
  
      return this.http.get( this.url + this.apiUrl + '/states?country=' + _countryId, httpOptions)
        .pipe(
          tap(res => {
            return res;
          })
        )   
    }


    get items() {
      return this.cart;
  
    }
  
    set items(newFile: any) {
      this.cart.push(newFile);
      //console.log('Carrito:', this.cart);
    }


    /*******************************************************************************
   * 
   * STORAGE
   * 
   *********************************************************************************/


  cargarStorage(){

    let promesa =  new Promise<void>( (resolve, reject) =>{

      if ( this.platform.is('cordova') ){

        this.storage.ready().then( ()=> {
  
          this.storage.get('storage-user')
              .then( datos => {
    
                if ( datos ){

                  console.log( 'version storage=', datos.ajustes.version );

                  if( datos.ajustes.version === this._storage.ajustes.version ){
                    this._storage = datos;
                  }else{
                    //alert('Nueva versión')
                    this.borrarStorage();
                  }
                  
                }
                
                resolve();
                
              });
    
        });
  
      }else{

        if( localStorage.getItem('storage-user')){

          let temp_storage = JSON.parse( localStorage.getItem('storage-user') );

          
          console.log( 'version storage=', temp_storage.ajustes.version );

          if( temp_storage.ajustes.version === this._storage.ajustes.version ){
            this._storage =  JSON.parse( localStorage.getItem('storage-user') );
          }else{
            //alert('Nueva versión')
            this.borrarStorage();
          }
          
          

        }
  
        resolve();
  
      }

    });

    return promesa;
  }




  guardarStorage(){

    //console.log('guardo storage');
    let promesa =  new Promise<void>( (resolve, reject) =>{


        if( this.platform.is('cordova') ){

          this.storage.ready()
              .then( ()=> {

                this.storage.set('storage-user', this._storage ).then( () => {
                  //console.log('storage guardado');

                  resolve();
                })
      
          });

        }else{

          localStorage.setItem('storage-user', JSON.stringify( this._storage));
          resolve();
        
        }

    });

  return promesa;

  }

  
  borrarStorage(){

    if( this.platform.is('cordova') ){

      this.storage.ready()
          .then( ()=> {

            this.storage.clear()
            .then( () => {
              console.log('Todas las llaves borradas');
            })
  
      });

    }else{

      localStorage.clear();
      console.log('Todas las llaves borradas');
    }

    this._storage.ajustes.logueado = false;
    this._storage.ajustes.token = null;
    this._storage.usuariodata = null;


  }


    /*************************************************************************
  * 
  * ONE SIGNAL
  * 
  ************************************************************************/


 initOneSignal(){

  this.oneSignal.startInit( this.oneSignalAppId , this.sender_id);

  // do something when a notification is opened
  this.oneSignal.handleNotificationOpened()
      .subscribe((res) => {

        //console.log( 'Pagina actual:', this.router.url  );

        console.log( 'mensaje es:',  res.notification.payload.additionalData.view );

        if( res.notification.payload.additionalData.view == 'nuevo_producto' ){
          this.newProduct.next( true );
        }
        else {
          this.router.navigate([res.notification.payload.additionalData.view]);
        
        }

    
  });

  this.oneSignal.endInit();

                 
}



}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController, NavParams, Platform, IonSlides } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ComponentsService } from 'src/app/services/components.service';
import { ApirestService } from 'src/app/services/apirest.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-tienda-detail',
  templateUrl: './tienda-detail.page.html',
  styleUrls: ['./tienda-detail.page.scss'],
})
export class TiendaDetailPage implements OnInit {

  @ViewChild(IonSlides, {static: false})slider: IonSlides ;

  producto:any = {};
  formProducto: FormGroup;
  variantes: any[] = [];
  cantidad = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    pagination:{
      el: '.swiper-pagination',
      type: "bullets",
      dynamicBullets: true,
    }
  };

  varianteName: string;
  precioUnitario: number;
  precioUnitario_moneda: number = null;
  varianteId: string;
  precioTotal: number = 0;
  precioTotal_moneda: number = 0;
  precio_simbolo: any = null;
  precio_iso: any = null;

  isHidden: boolean = false;

  constructor( public modalCtrl: ModalController,
            public navParams: NavParams,
            private formBuilder: FormBuilder,
            private platform: Platform,
            private ga: GoogleAnalytics,
            private componentsProvider: ComponentsService,
            private apirestProvider: ApirestService
            ) { 

              this.platform.backButton.subscribeWithPriority(10, () => {
                console.log('Handler was called!');
                this.closeModal(false);
              });

  }

  ionViewDidEnter(){
    this.slider.update();
  }

  ionViewDidLoad(){
    this.platform.ready().then(() => {
      this.ga.trackView('Tienda producto')
          .then(() => {})
          .catch(e => console.log(e));
    });
    
  }

  ngOnInit() {

    this.producto = this.navParams.data; 
    this.precioUnitario = parseFloat(this.producto.precio);

    if (this.producto.precio_moneda != null) {
      this.precioUnitario_moneda = parseFloat(this.producto.precio_moneda.valor);
    }

    console.log(this.producto);

    this.formProducto = this.formBuilder.group({
      size: ['', Validators.required],
      qty: ['', Validators.required]
    });

    this.formProducto.get('qty').setValue( this.cantidad[0]); 

    if( this.producto['variantes'].length == 1 ){
      this.formProducto.get('size').setValue( this.producto['variantes'][0]); 
      this.isHidden = true;
      this.varianteId = this.producto['variantes'][0].id;
      this.varianteName = this.producto['variantes'][0].titulo;

      this.calcularTotal( this.producto['variantes'][0].precio, this.producto['variantes'][0].precio_moneda.valor );
    }
    
  }

  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }


  onChangeSize(ev) {

    this.varianteId = ev.detail.value.id;
    this.varianteName = ev.detail.value.titulo;

    if (this.formProducto.value.qty == undefined || this.formProducto.value.qty == null || this.formProducto.value.qty == '') {

      this.precioTotal = parseFloat(ev.detail.value.precio) * 1;
      if (this.producto.precio_moneda != null) {
        this.precioTotal_moneda = parseFloat(ev.detail.value.precio_moneda.valor) * 1;
        this.precio_simbolo = this.producto.precio_moneda.simbolo;
        this.precio_iso = this.producto.precio_moneda.moneda;
      }

      console.log('opcion 1');

    } else {

      /* this.precioTotal = parseFloat(this.formProducto.value.qty) * parseFloat(ev.detail.value.precio);
      if (this.producto.precio_moneda != null) {
        this.precioTotal_moneda = parseFloat(this.formProducto.value.qty) * parseFloat(ev.detail.value.precio_moneda.valor);
        this.precio_simbolo = this.producto.precio_moneda.simbolo;
        this.precio_iso = this.producto.precio_moneda.moneda;
      } */

      this.calcularTotal(ev.detail.value.precio,ev.detail.value.precio_moneda.valor );

      console.log('opcion 2');

    }

    //console.log('Precio Total = ', this.precioTotal);
    //console.log('Precio Total Moneda= ', this.precioTotal_moneda);



  }

  calcularTotal( _precio, _precio_moneda ){

    this.precioTotal = parseFloat(this.formProducto.value.qty) * parseFloat(_precio);
      if (this.producto.precio_moneda != null) {
        this.precioTotal_moneda = parseFloat(this.formProducto.value.qty) * parseFloat(_precio_moneda);
        this.precio_simbolo = this.producto.precio_moneda.simbolo;
        this.precio_iso = this.producto.precio_moneda.moneda;
      }

      //console.log('Precio Total = ', this.precioTotal);
    //console.log('Precio Total Moneda= ', this.precioTotal_moneda);

  }

  onChangeQty(ev) {

    let cantidad: number = parseFloat(ev.detail.value);

    let precioTemp: number = this.precioUnitario * cantidad;
    this.precioTotal = precioTemp;

    if (this.producto.precio_moneda != null) {
      let precioTemp2: number = this.precioUnitario_moneda * cantidad;
      this.precioTotal_moneda = precioTemp2;
      this.precio_simbolo = this.producto.precio_moneda.simbolo;
      this.precio_iso = this.producto.precio_moneda.moneda;
    }



    //console.log('precio=', precioTemp);

  }

  agregarCarrito() {

    let item = {

      'idproducto': this.producto.id,
      'idvariante': this.varianteId,
      'nombre': this.producto.titulo,
      'namevariante': this.varianteName,
      'imagen': this.producto.imagen,
      'cantidad': this.formProducto.value.qty,
      'precio': this.precioTotal,
      'precio_moneda': this.precioTotal_moneda,
      'precio_simbolo': this.precio_simbolo,
      'precio_iso': this.precio_iso
    }

    //console.log(item);

    // ? agrego el archivo al array de archivos
    this.apirestProvider.items = item;

    this.closeModal(true);

  }



}

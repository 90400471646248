import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApirestService } from 'src/app/services/apirest.service';
import { ComponentsService } from 'src/app/services/components.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-config-useredit',
  templateUrl: './config-useredit.page.html',
  styleUrls: ['./config-useredit.page.scss'],
})
export class ConfigUsereditPage implements OnInit {

  user:any = {};
  formPerfil: FormGroup;

  constructor(public modalCtrl: ModalController,
    public navParams: NavParams,
    public formBuilder: FormBuilder,
    private platform: Platform,
    public loadingCtrl: LoadingController,
    private apirestProvider: ApirestService,
    private componentsProvider: ComponentsService) { 

      this.platform.backButton.subscribeWithPriority(10, () => {
        console.log('Handler was called!');
        this.closeModal(false);
      });
  }

  

  ngOnInit() {

    this.user = this.navParams.data; 
    console.log(this.user);


    this.formPerfil = this.formBuilder.group({
      
      name: ['', Validators.required],
      phone: [''],
      password: [''],
      re_password: [''],

    });

    this.formPerfil.get('name').setValue(this.user['name']);
    this.formPerfil.get('phone').setValue(this.user['phone']);

  }


  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }

  async guardarCambiosPerfil(){

    const loading = await this.loadingCtrl.create({
      message: 'cargando ...'
    });
    await loading.present();


    if( this.formPerfil.value.password == '' ){

      let objTemp = this.formPerfil.value;
            delete objTemp.password;
            delete objTemp.re_password;

        this.apirestProvider.putProfile( objTemp )
                .subscribe( datos => {

                  loading.dismiss();

                  //console.log(datos);

                  this.componentsProvider.presentToast( 'Cambios guardados' );

                  this.closeModal( true );

                },
                error => {
                  console.error(error);
                  loading.dismiss();
                  this.componentsProvider.presentToast( 'No se pudieron guardar los cambios' );

                });    

          }else {

             if ( this.formPerfil.value.password != this.formPerfil.value.re_password  ){

              loading.dismiss();
              return this.componentsProvider.singleAlert('ERROR', '', 'Contraseña y confirmar contraseña deben ser iguales' );
        
            }

            let objTemp = this.formPerfil.value;
            delete objTemp.re_password;

            this.apirestProvider.putProfile( objTemp )
            .subscribe(datos => {
              //console.log( datos );
              loading.dismiss();

              this.componentsProvider.presentToast( 'Cambios guardados' );

              this.closeModal( true );

            },
            error =>{
              console.error(error);
              loading.dismiss();
              this.componentsProvider.presentToast( 'No se pudieron guardar los cambios' );

            });  

          } 



  }


}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'consejos',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'consejos',
    loadChildren: () => import('./pages/consejos/consejos.module').then( m => m.ConsejosPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./pages/registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'recuperar',
    loadChildren: () => import('./pages/recuperar/recuperar.module').then( m => m.RecuperarPageModule)
  },
  {
    path: 'configura-inicio',
    loadChildren: () => import('./pages/configura-inicio/configura-inicio.module').then( m => m.ConfiguraInicioPageModule)
  },
  {
    path: 'tienda',
    loadChildren: () => import('./pages/tienda/tienda.module').then( m => m.TiendaPageModule)
  },
  {
    path: 'tienda-detail',
    loadChildren: () => import('./pages/tienda-detail/tienda-detail.module').then( m => m.TiendaDetailPageModule)
  },
  {
    path: 'carrito',
    loadChildren: () => import('./pages/carrito/carrito.module').then( m => m.CarritoPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'acerca',
    loadChildren: () => import('./pages/acerca/acerca.module').then( m => m.AcercaPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'recetarios',
    loadChildren: () => import('./pages/recetarios/recetarios.module').then( m => m.RecetariosPageModule)
  },
  {
    path: 'testimoniales',
    loadChildren: () => import('./pages/testimoniales/testimoniales.module').then( m => m.TestimonialesPageModule)
  },
  {
    path: 'administracion',
    loadChildren: () => import('./pages/administracion/administracion.module').then( m => m.AdministracionPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'programas',
    loadChildren: () => import('./pages/programas/programas.module').then( m => m.ProgramasPageModule)
  },
  {
    path: 'programas-semanas',
    loadChildren: () => import('./pages/programas-semanas/programas-semanas.module').then( m => m.ProgramasSemanasPageModule)
  },
  {
    path: 'programas-dias',
    loadChildren: () => import('./pages/programas-dias/programas-dias.module').then( m => m.ProgramasDiasPageModule)
  },
  {
    path: 'programas-dia-vista',
    loadChildren: () => import('./pages/programas-dia-vista/programas-dia-vista.module').then( m => m.ProgramasDiaVistaPageModule)
  },
  {
    path: 'recetarios-list',
    loadChildren: () => import('./pages/recetarios-list/recetarios-list.module').then( m => m.RecetariosListPageModule)
  },
  {
    path: 'recetarios-receta',
    loadChildren: () => import('./pages/recetarios-receta/recetarios-receta.module').then( m => m.RecetariosRecetaPageModule)
  },
  {
    path: 'rutinas-especiales',
    loadChildren: () => import('./pages/rutinas-especiales/rutinas-especiales.module').then( m => m.RutinasEspecialesPageModule)
  },
  {
    path: 'rutinas-especiales-menu',
    loadChildren: () => import('./pages/rutinas-especiales-menu/rutinas-especiales-menu.module').then( m => m.RutinasEspecialesMenuPageModule)
  },
  {
    path: 'rutinas-especiales-semanas',
    loadChildren: () => import('./pages/rutinas-especiales-semanas/rutinas-especiales-semanas.module').then( m => m.RutinasEspecialesSemanasPageModule)
  },
  {
    path: 'rutinas-especiales-ejercicios',
    loadChildren: () => import('./pages/rutinas-especiales-ejercicios/rutinas-especiales-ejercicios.module').then( m => m.RutinasEspecialesEjerciciosPageModule)
  },
  {
    path: 'rutinas-especiales-dias',
    loadChildren: () => import('./pages/rutinas-especiales-dias/rutinas-especiales-dias.module').then( m => m.RutinasEspecialesDiasPageModule)
  },
  {
    path: 'rutinas-especiales-rutina',
    loadChildren: () => import('./pages/rutinas-especiales-rutina/rutinas-especiales-rutina.module').then( m => m.RutinasEspecialesRutinaPageModule)
  },
  {
    path: 'timer-cron',
    loadChildren: () => import('./pages/timer-cron/timer-cron.module').then( m => m.TimerCronPageModule)
  },
  {
    path: 'programas-sustitutos',
    loadChildren: () => import('./pages/programas-sustitutos/programas-sustitutos.module').then( m => m.ProgramasSustitutosPageModule)
  },
  {
    path: 'calificaciones',
    loadChildren: () => import('./pages/calificaciones/calificaciones.module').then( m => m.CalificacionesPageModule)
  },
  {
    path: 'programas-fecha',
    loadChildren: () => import('./pages/programas-fecha/programas-fecha.module').then( m => m.ProgramasFechaPageModule)
  },
  {
    path: 'rutinas-especiales-fecha',
    loadChildren: () => import('./pages/rutinas-especiales-fecha/rutinas-especiales-fecha.module').then( m => m.RutinasEspecialesFechaPageModule)
  },
  {
    path: 'checkout-referencia',
    loadChildren: () => import('./pages/checkout-referencia/checkout-referencia.module').then( m => m.CheckoutReferenciaPageModule)
  },
  {
    path: 'perfil-edit',
    loadChildren: () => import('./pages/perfil-edit/perfil-edit.module').then( m => m.PerfilEditPageModule)
  },
  {
    path: 'config-datosedit',
    loadChildren: () => import('./pages/config-datosedit/config-datosedit.module').then( m => m.ConfigDatoseditPageModule)
  },
  {
    path: 'calificaciones-edit',
    loadChildren: () => import('./pages/calificaciones-edit/calificaciones-edit.module').then( m => m.CalificacionesEditPageModule)
  },
  {
    path: 'calificaciones-historico',
    loadChildren: () => import('./pages/calificaciones-historico/calificaciones-historico.module').then( m => m.CalificacionesHistoricoPageModule)
  },
  {
    path: 'calificaciones-detail',
    loadChildren: () => import('./pages/calificaciones-detail/calificaciones-detail.module').then( m => m.CalificacionesDetailPageModule)
  },
  {
    path: 'address-add',
    loadChildren: () => import('./pages/address-add/address-add.module').then( m => m.AddressAddPageModule)
  },  {
    path: 'config-useredit',
    loadChildren: () => import('./pages/config-useredit/config-useredit.module').then( m => m.ConfigUsereditPageModule)
  },
  {
    path: 'calificaciones-mensaje',
    loadChildren: () => import('./pages/calificaciones-mensaje/calificaciones-mensaje.module').then( m => m.CalificacionesMensajePageModule)
  },
  {
    path: 'playlist',
    loadChildren: () => import('./pages/playlist/playlist.module').then( m => m.PlaylistPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

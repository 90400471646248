import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { ApirestService } from 'src/app/services/apirest.service';
import { ComponentsService } from 'src/app/services/components.service';

@Component({
  selector: 'app-programas-fecha',
  templateUrl: './programas-fecha.page.html',
  styleUrls: ['./programas-fecha.page.scss'],
})
export class ProgramasFechaPage implements OnInit {

  @Input() requisitos: any;
  @Input() id: any;

  today:any;
  formAgendar:FormGroup;
  myCurrentDate:any;
  myPastDate:any;
  limit:any;
  myCurrentYear:any;
   
  //idProgram:any;
  //requisitos:string;

  constructor(public loadingCtrl:LoadingController,
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    public platform: Platform,
    private apirestProvider:ApirestService,
    private componentsProvider: ComponentsService
    ) { 
      this.platform.backButton.subscribeWithPriority(10, () => {
        console.log('Handler was called!');
        this.closeModal(false);
      });
    }

    

  ngOnInit() {

    //console.log('requisitos:', this.requisitos );
    //console.log('Id:', this.id );

    this.myCurrentDate = new Date();
    this.myPastDate=new Date(this.myCurrentDate);
    this.myPastDate.setDate(this.myPastDate.getDate() - 30);
    this.myCurrentYear =  new Date().getFullYear();

    this.formAgendar = this.formBuilder.group({
      fechainicio: ['', Validators.required ]
    });

    this.limit = this.myPastDate.toJSON().split('T')[0];
    this.today = new Date().toJSON().split('T')[0];
    this.formAgendar.get('fechainicio').setValue(this.today);

  }

  async programarInicio(){

    const loading = await this.loadingCtrl.create({
      message: 'guardando fecha...'
    });
    await loading.present();

    let tempDate:string = this.formAgendar.value.fechainicio.toString();
    let fechaI:string = tempDate.split('T')[0];

    this.apirestProvider.putStartProgramDate( this.id, fechaI, 'start' )
              .subscribe(datos => {
                //this.programs = datos.data;

                if (datos['code'] == 1){
                    
                  this.closeModal( true );
                  console.log(datos);

                }else if(datos['code'] == 0){
                  this.componentsProvider.singleAlert('ERROR', '', datos['message'] );
                }

                loading.dismiss();   

              },
              error => {
                console.error(error);
                loading.dismiss();     
              });   



  }

  async cancelarFecha(){

    const loading = await this.loadingCtrl.create({
      message: 'guardando ...'
    });
    await loading.present();


          this.apirestProvider.putStartProgramDate( this.id, this.formAgendar.value.fechainicio, 'stop')
              .subscribe(datos => {
                //this.programs = datos.data;
                loading.dismiss();  
                this.closeModal( true );
                console.log(datos);
              },
              error => {
                console.error(error);
                loading.dismiss();  
              });   

 

  }


  async closeModal(valor:boolean) {
    await this.modalCtrl.dismiss(valor);
  }

}
